<template>
  <div id="warehouse-entry-out-card">
    <div class="card">
      <div class="header">
        <div>Entradas x Saídas</div>
        <date-picker
          range
          v-model="period"
          format="DD [de] MMMM"
          :clearable="true"
          :lang="langDatePicker"
        />
      </div>
      <div class="chart">
        <apexchart
          type="bar"
          width="100%"
          height="auto"
          :options="options"
          :series="series"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    'apexchart': VueApexCharts,
  },
  mounted() {
    this.getChartEntranceVsOutput();
  },
  data() {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      period: [start, end],
      series: [
        { name: 'Entradas', data: [], color: '#305BF2' },
        { name: 'Saídas', data: [], color: '#FBA59D' }
      ],
      options: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          position: 'bottom',
          categories: [],
        }
      },
      langDatePicker: {
        formatLocale: {
	        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      },
    }
  },
  methods: {
    getChartEntranceVsOutput() {
      this.api.getChartEntranceVsOutput(this.clinic.id, this.period[0], this.period[1])
      .then(res => {
        this.series[0] = Object.assign({}, { ...this.series[0], data: res.data.entries });
        this.series[1] = Object.assign({}, { ...this.series[1], data: res.data.outputs });
        this.options = Object.assign({}, {
          ...this.options,
          xaxis: { ...this.options.xaxis, categories: res.data.labels }
        });
      })
      .catch(err => this.$toast.error(err.message))
    }
  },
  computed: {
    styles() {
      return {
        position: 'relative',
        width: '100%',
      }
    }
  },
  watch: {
    period: function() { this.getChartEntranceVsOutput() }
  }
}
</script>
<style lang="scss">
#warehouse-entry-out-card {
  width: 100%;
  height: 100%;

  .card {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    text-align: left;
  }
  .header {
    display: flex;
    padding: 20px 20px 0 20px;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-blue);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
    flex: 0.1;
  }
  .chart {
    width: 100%;
    flex: 1;
  }
  .mx-datepicker {
    width: 260px;
    .mx-input-wrapper {
      .mx-input {
        height: 38px;
        padding: 0 16px;
        border: 0;
        box-shadow: none;
        color: var(--type-active);
        font-weight: 600;
        font-size: 14px;
        text-align: right !important;

        &:focus {
          border: 1px solid var(--blue-500);
        }
      }
    }
  }
}
</style>
